// Main Application Component
import Main from './components/main/main';

function App() {
  return (
    <div className="App">
      <Main></Main>
    </div>
  );
}

export default App;
